<template>
  <v-container class="listProgettiOperatore">
    <v-card class="card-shadow">
      <div class="card-border-bottom pa-3">
        <p class="font-weight-600 text-h3 text-typo mb-0">Lista Progetti</p>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          no-data-text="Ancora nessun dato"
          :headers="headers"
          :items="progetti"
          :items-per-page="itemsPerPage"
          :page="page"
          :search="search"
          hide-default-footer
          class="table thead-light"
          mobile-breakpoint="0"
          @click:row="apriProgetto"
          :item-class="
            () => {
              return 'pointer';
            }
          "
        >
          <template v-slot:top>
            <v-toolbar flat height="65">
              <v-row>
                <v-col cols="12" md="8" class="pt-0 pb-0">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    style="max-width: 250px"
                    dense
                    flat
                    filled
                    solo
                    height="30"
                    v-model="search"
                    placeholder="Cerca"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item.codice_commessa="{ item }">
            <h3 class="ml-2">{{ item.codice_commessa }}</h3>
          </template>
        </v-data-table>
      </v-card-text>
      <div class="pa-2 d-flex justify-end">
        <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="primary"
          v-model="page"
          :length="numberOfPages"
          circle
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import apiProgetti from "@/js/pages/progetti";

export default {
  name: "listProgettiOperatore",
  data() {
    return {
      progetti: [],
      token: this.$cookies.get("token_goldencar"),
      ruolo: this.$store.state.ruolo,
      search: "",
      page: 1,
      itemsPerPage: 10,
      headers: [
        { 
          text: "C. Commessa", 
          value: "codice_commessa", 
          width: "25%" 
        },
        { text: "Nome", value: "nome", width: "35%" },
        { text: "Descrizione", value: "descrizione", width: "35%" },
      ],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.progetti.length / this.itemsPerPage);
    },
  },
  async created() {
    await this.reloadLista();
  },
  methods: {
    async reloadLista() {
      let progetti = await apiProgetti.getAll(this.token);
      this.progetti = progetti;
    },
    apriProgetto(item = {}) {
      this.$router.push({
        path: "/listParticolariOperatore",
        query: {
          id_progetto: item["_id"],
        },
      });
    },
  },
};
</script>